<script lang="ts" setup>
const year = (new Date()).getFullYear()
</script>

<template lang="pug">
footer.pt-20.pb-8.bg-slate-700.text-slate-50
  .container.mx-auto.px-3
    .flex.gap-12.justify-between.flex-col(class="md:flex-row")
      div
        img(src="/images/logo-white.svg" alt="Abmeldung Digital" style="height: 30px; width: auto;")
      .links.flex.gap-6.flex-col(class="md:flex-row lg:gap-8")
        nuxt-link(to="/staedte") Städte
        nuxt-link(to="/landkreise") Landkreise
    hr.my-6.border-slate-900
    .flex.justify-between.flex-col-reverse.gap-10(class="md:flex-row")
      span.text-sm.font-thin © {{ year }} blackbird GmbH
      .links.flex.gap-6.flex-col(class="md:flex-row lg:gap-8")
        nuxt-link(to="/impressum") Impressum
        nuxt-link(to="/datenschutz") Datenschutz
        nuxt-link(to="/agb") AGB
        nuxt-link(to="/widerrufsrecht") Widerrufsrecht
</template>

<style lang="sass">

</style>
